<template>
    <div class="bannerContent">
        <div class="logo">
            <img alt="" src="img/logo.svg"/>
        </div>

        <!-- Images -->
        <div class="bannerImg">
            <swiper :navigation="true" :options="swiperOption" class="swiper">
                <swiper-slide v-bind:key="banner" v-for="banner in banners">
                    <img :src="banner" alt="no image"/>
                </swiper-slide>
            </swiper>
        </div>

        <!--  Subtitle  -->
        <div
                class="subBannerText"
                data-aos="fade-right"
                data-aos-duration="3000"
                v-if="!hasBannerTitle"
        >
            <p>一個櫃子怎麼辦？要叫一台車好貴！</p>
            <p>一台電視怎麼寄？花蓮到台南誰可以？</p>
        </div>

        <!-- Title -->
        <div
                class="bannerText"
                data-aos="fade-right"
                data-aos-delay="100"
                data-aos-duration="3000"
        >
            <p>{{ title }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Banner",
        props: {
            banners: {
                type: Array,
                required: true
            },
            banner_title: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                swiperOption: {
                    autoplay: {
                        disableOnInteraction: false,
                        stopOnLastSlide: false,
                        delay: 2000
                    },
                    speed: 800,
                    grabCursor: true,
                    loop: true,
                    freeMode: true,
                    // centeredSlides: true,
                    breakpoints: {
                        1024: {
                            slidesPerView: 1,
                            spaceBetween: 0
                        },
                        991: {
                            slidesPerView: 1,
                            spaceBetween: 0
                        },
                        800: {
                            slidesPerView: 1,
                            spaceBetween: 0
                        },
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 0
                        },
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 0
                        }
                    },
                    pagination: {
                        el: ".swiper-pagination",
                        type: "bullets",
                        clickable: true
                    },
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev"
                    }
                    // pagination: {
                    //   el: ".swiper-pagination",
                    //   clickable: true,
                    //   type: "bullets"
                    // },
                    // navigation: {
                    //   nextEl: ".swiper-button-next",
                    //   prevEl: ".swiper-button-prev"
                    // }
                }
            };
        },
        computed: {
            swiper() {
                return this.$refs.mySwiper.$swiper;
            },
            hasBannerTitle() {
                return this.banner_title;
            },
            title() {
                return this.hasBannerTitle || "try BIGBOX";
            }
        },
        mounted() {
        },
        methods: {}
    };
</script>
