export default {
    itemsA() {
        return [
            {item: "1箱子可裝載25公斤（K801箱子尺寸：69 x 48 x 38 cm；容量：85L）"},
            {item: "收大型物件（床墊、小冰箱…等），不收冷凍食品及有生命之活物"},
            {
                item:
                    "下單時間與取貨時間之間隔不得小於三天，如下單有誤造成無法配送，將全額退費，但手續費須由顧客支出。"
            },
            {
                item:
                    "下單24小時內須匯款完成才會進行排單，以匯款時間作為排單根據。若超過時間匯款則須重新排單。"
            },
            {
                item:
                    "裝箱時間為15分鐘（以司機交予客戶箱子後開始計算），每超過(滿)15分鐘需負擔200元的拖延費給司機"
            },
            {
                item:
                    "託運人於託運貨品時，應依表單之內容詳實填載，若有疑義或地址不明者，運送人得檢驗之。訂單之填寫如有虛偽不實者，運送人不負任何運送損害賠償責任。"
            },
            {
                item:
                    "本網站為第三方平台提供『共享物流』之服務，僅負責消費者與司機間的媒合，建議消費者事先和司機約定好時段取貨，若有發生任何取/收貨時間上的誤差，本公司恕無法提供賠償。"
            },
            {
                item:
                    "交貨時間前一個小時司機會打給客戶，依照原定時間抵達10分鐘後都未聯絡到人，將視為已收貨，不得要求退費"
            },
            {
                item: "可以用行李箱折抵箱子，25吋(含)以下折抵1個，25吋(不含)以上=2個"
            },
            {
                item: "機車不收檔車"
            },
            {
                item: "機車不超過150c.c.，且可加購保護套（＄200）"
            },
            {
                item: "機車包裝問題"
            },
            {
                item: "1. 客戶可以自己包裝"
            },
            {
                item: "2. 加價購200元保護套，由客戶自行包裝（擦傷不賠償，但後照鏡破裂、輪胎少一顆…等重大損害可理賠）"
            },
            {
                item: "不因距離遠近影響價錢（須注意是否為服務地區）"
            },
            {
                item: "如需協助搬運上樓，走樓梯一樓加收50元/箱，有電梯不論樓層則加收30元/箱。"
            },
            // {
            //     item: "司機可以到一樓門口幫忙搬運，若有需要上樓服務，有電梯加價100元，走樓梯一層樓+200元"
            // },
            // {
            //     item: "24小時內到貨"
            // },
            // {
            //     item: "箱子送達後歸還給司機"
            // },
            {
                item: "交貨時，若貨品超過下單時的數量，司機有權不收多餘之貨品，若有超收貨品的問題可以先聯絡客服"
            },
            {
                item: "假日不休息；遇國定假日則休息，恕不提供服務"
            }
        ];
    },

    itemsB() {
        return [
            {
                item: "已採購方案1或方案2的客戶，可以另外加購箱子，1箱子300元"
            },
            {
                item:
                    "專車方案：一車12000元 = 至多2 台摩托車+35 箱子(司機可以視情況放置)，上下車地點最多可安排五個"
            }
        ];
    },

    itemsC() {
        return [
            {
                item: "可針對單一物件加保「保值加價險」"
            },
            {
                item: "若貨品損壞且經查證為運輸疏失，將視情況賠償"
            }
        ];
    },

    itemsD() {
        return [
            {
                item: "兩個禮拜前要求取消，費用退100%"
            },
            {
                item: "一個禮拜前要求取消，費用退50% "
            },
            {
                item: "三天前要求取消，費用不退"
            },
            {
                item: "以上手續費均由客戶負擔"
            }
        ];
    },

    itemsE() {
        return [
            {
                item: "原則上基隆至高雄都送，交流道附近可收，但西濱、山上目前不服務"
            },
            {
                item: "偏鄉地區找五個人湊一車可以替他們送（暨南大學、佛光大學…等）"
            }
        ];
    },

    itemsF() {
        return [
            {
                object: "單人床墊",
                offsetting_amount_of_box: "1個",
                note: "彈簧床=4個"
            },
            {
                object: "折疊椅",
                offsetting_amount_of_box: "1個",
                note: ""
            },
            {
                object: "電腦一組",
                offsetting_amount_of_box: "1個",
                note: "含主機、鍵盤、螢幕<= 32吋"
            },
            {
                object: "空氣清淨機",
                offsetting_amount_of_box: "1個",
                note: ""
            },
            {
                object: "棉被",
                offsetting_amount_of_box: "1個",
                note: "塞不進箱子的"
            },
            {
                object: "洗衣籃",
                offsetting_amount_of_box: "1個",
                note: "裡面可以放自己的東西，要自行封起來"
            },
            {
                object: "電風扇(直立式)",
                offsetting_amount_of_box: "2個",
                note: "箱型電風扇算1個"
            },
            {
                object: "衣帽架",
                offsetting_amount_of_box: "1個",
                note: ""
            },
            {
                object: "晾衣架",
                offsetting_amount_of_box: "1個",
                note: ""
            },
            {
                object: "收納架",
                offsetting_amount_of_box: "1個",
                note: ""
            },
            {
                object: "行李箱",
                offsetting_amount_of_box: "1~2個",
                note: "25吋(含)以下=1個；25吋(不含)以上=2個"
            },
            {
                object: "櫃子",
                offsetting_amount_of_box: "2~3個",
                note: "1層、2層櫃子 = 2 個；3層、4層櫃子 = 3個"
            },
            {
                object: "小桌子",
                offsetting_amount_of_box: "2個",
                note: "木製品損傷自己負責"
            },
            {
                object: "電子琴",
                offsetting_amount_of_box: "2個",
                note: "鋼琴不收"
            },
            {
                object: "吉他",
                offsetting_amount_of_box: "2個",
                note: ""
            },
            {
                object: "小冰箱",
                offsetting_amount_of_box: "2個",
                note: ""
            },
            {
                object: "畫",
                offsetting_amount_of_box: "2~4個",
                note: "90:120 = 2個箱子；120:150 = 3個箱子；150:180 = 4個箱子"
            },
            {
                object: "全身鏡",
                offsetting_amount_of_box: "3個",
                note: ""
            },
            {
                object: "電腦椅",
                offsetting_amount_of_box: "3個",
                note: ""
            },
            {
                object: "腳踏車",
                offsetting_amount_of_box: "3個",
                note: "需自行包裝，注意齒輪油問題"
            },
            {
                object: "洗衣機",
                offsetting_amount_of_box: "0個",
                note: "不收"
            },
            {
                object: "雙人床墊",
                offsetting_amount_of_box: "0個",
                note: "不收"
            },
            {
                object: "客製化",
                offsetting_amount_of_box: "",
                note: ""
            }
        ];
    },

    plans() {
        return [
            {value: "plan5", text: "純機車  ------------------ 1900"},
            {value: "plan6", text: "5個箱子 ------------------ 1900"},
            {value: "plan7", text: "機車 + 4個箱子 ------------ 2900"},
            {value: "plan8", text: "機車 + 9個箱子 ------------ 3600"},
            {value: "plan9", text: "2機車 + 35個箱子(專車) ---- 13000"},
            {value: "plan4", text: "客製化服務"}
        ];
    },

    header() {
        return [
            {
                key: "item",
                label: "項目"
            }
        ];
    },

    headerF() {
        return [
            {
                key: "object",
                label: "物品"
            },
            {
                key: "offsetting_amount_of_box",
                label: "折抵箱子📦"
            },
            {
                key: "note",
                label: "備註"
            }
        ];
    }
};
