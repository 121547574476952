<template>
    <div class="indexContainer">
        <div class="container-fluid bmd-layout-container bmd-drawer-f-l bmd-drawer-overlay">
            <banner :banners="banner01" banner_title="謝謝您的填寫"/>
        </div>

        <div class="mainContent formContent">
            <div class="mainTitle" data-aos="fade-up" data-aos-duration="2000">
                <p>感謝</p>
            </div>
            <div class="formInner">
                <h2>
                    <ul>
                        <li>您選擇：{{ this.params.payment.display_name }}</li>
                        <li>您的訂單編號：{{ this.serialNumber }}</li>
<!--                        <li>總額：{{ this.total }}</li>-->
                    </ul>
                </h2>
                <h2>請將訂單編號盡快傳給客服確認，感謝您！</h2>
                <h2>
                    官方Line：@412aiabg
                    <a href="https://lin.ee/gQd06hs">
                        <img height="36" src="https://scdn.line-apps.com/n/line_add_friends/btn/zh-Hant.png" alt="line">
                    </a>
                </h2>

                <b-table v-if="is_show_bank_info"
                         :bordered="true"
                         :fields="this.getItemData.bank_headers()"
                         :items="this.getItemData.bank_items()"
                         head-variant="dark"
                         sticky-header/>

<!--                <b-table :bordered="true"-->
<!--                         :fields="headers_of_sender"-->
<!--                         :items="infos_of_sender"-->
<!--                         head-variant="dark"-->
<!--                         sticky-header/>-->

<!--                <b-table :bordered="true"-->
<!--                         :fields="headers_of_picker"-->
<!--                         :items="infos_of_picker"-->
<!--                         head-variant="dark"-->
<!--                         sticky-header/>-->
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import ItemData from "./ThanksData.js";
    import ItemDataContactUs from "../components/ContactUs/Data.js";
    import Banner from "@/view/components/Banner";

    export default {
        name: "Thanks",
        data: function () {
            return {
                banner01: [
                    "img/banner10.jpg",
                    // "img/gettyimages-172155755-2048x2048.jpg",
                    // "img/gettyimages-1016102754-2048x2048.jpg",
                    // "img/gettyimages-1158696205-2048x2048.jpg",
                    // "img/gettyimages-1177117400-2048x2048.jpg"
                ],

                params: {},

                total: 0,

                is_show_bank_info: false,

                // sender infos
                infos_of_sender: [],
                headers_of_sender: [
                    {
                        key: "title",
                        label: "寄件者 項目"
                    },
                    {
                        key: "value",
                        label: "寄件者 內容"
                    }
                ],

                // picker infos
                infos_of_picker: [],
                headers_of_picker: [
                    {
                        key: "title",
                        label: "取件人 項目"
                    },
                    {
                        key: "value",
                        label: "取件人 內容"
                    }
                ]
            };
        },
        components: {
            Banner
        },
        computed: {
            ...mapGetters(["serialNumber", "geographic"]),
            getItemData() {
                return ItemData;
            },
            getPlans() {
                return ItemDataContactUs.plans();
            }
        },
        created() {

            this.params = this.$route.params;

            const sender = this.params.sender;
            const picker = this.params.picker;
            const payment = this.params.payment;

            this.is_show_bank_info = (payment.value === 'remittance');

            // console.log(" this.getPlans ****** =>", this.getPlans);
            //
            // console.log(" sender ****** =>", sender);
            //
            // console.log(" picker ****** =>", picker);
            //
            // console.log(" payment ****** =>", payment);
            //
            // console.log(" geographic ****** =>", this.geographic);

            this.infos_of_sender = [
                {
                    'title': '地址',
                    'value': sender.address,
                    // 'value': picker.address . picker.selectCountry,
                },
                // {
                //     'title': '樓層',
                //     'value': sender.floor,
                // },
                // {
                //     'title': '是否有電梯',
                //     'value': sender.hasElevator,
                // },
                {
                    'title': '姓名',
                    'value': sender.name,
                },
                // {
                //     'title': '是否需要司機搬運',
                //     'value': sender.needPorter ? '✅' : '❌',
                // },
                {
                    'title': '方案',
                    'value': this.getPlans.find((e) => e.value === sender.plan).text,
                },
                {
                    'title': '加購幾個大盒子',
                    'value': sender.box,
                },
                {
                    'title': '是否加購摩托車保護套 ( 200 / 1 個 )',
                    'value': sender.need_motorcycle_protection ? '✅' : '❌',
                },
                {
                    'title': '是否需要搬運至電梯或上樓',
                    'value': sender.need_porter_depends_on_situation ? '✅' : '❌',
                },
                {
                    'title': '備註',
                    'value': sender.note,
                },
                {
                    'title': '電話',
                    'value': sender.phone,
                },
                {
                    'title': '時間',
                    'value': sender.time,
                },
            ];

            this.infos_of_picker = [
                {
                    'title': '地址',
                    'value': picker.address,
                    // 'value': picker.address . picker.selectCountry,
                },
                // {
                //     'title': '樓層',
                //     'value': picker.floor,
                // },
                // {
                //     'title': '是否有電梯',
                //     'value': picker.hasElevator,
                // },
                {
                    'title': '姓名',
                    'value': picker.name,
                },
                // {
                //     'title': '是否需要司機搬運',
                //     'value': picker.needPorter ? '✅' : '❌',
                // },
                {
                    'title': '備註',
                    'value': picker.note,
                },
                {
                    'title': '電話',
                    'value': picker.phone,
                },
                {
                    'title': '時間',
                    'value': picker.time,
                },
            ];

            // console.log(" name2 ****** =>", this.$route.params);
        }
    };
</script>
