export default {
    bank_items() {
        return [
            {
                key: "銀行",
                description: "第一銀行"
            },
            {
                key: "銀行代號",
                description: "007"
            },
            {
                key: "銀行分行",
                description: "南屯分行"
            },
            {
                key: "帳號",
                description: "40710020554"
            },
            // {
            //   key: "電話",
            //   description: "04-23801515"
            // },
            {
                key: "姓名",
                description: "盒子彈科技股份有限公司"
            }
        ];
    },

    bank_headers() {
        return [
            {
                key: "key",
                label: "項目"
            },
            {
                key: "description",
                label: "訊息"
            }
        ];
    }
};
